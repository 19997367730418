import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const EMAIL_WEBSITE_OWNER = gql`
  mutation EmailWebsiteOwner($websiteGuid: String!, $name: String!, $email: String!, $message: String!) {
    emailWebsiteOwner(websiteGuid: $websiteGuid, name: $name, email: $email, message: $message) {
      success
    }
  }
`;

export const ContactForm = ({ content }) => {
  const [
    emaiWebsiteOwner,
    {
      // data,
      loading
    }
  ] = useMutation(EMAIL_WEBSITE_OWNER);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const resetForm = () => {
    setIsSent(false);
    setName('');
    setEmail('');
    setMessage('');
    setErrMsg('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    emaiWebsiteOwner({ variables: { name, email, message, websiteGuid: process.env.REACT_APP_WEBSITE_GUID } });
    setIsSent(true);
  }

  return (
    <div className="container mt-10">
      <h1 className="text-4xl">{content.header}</h1>
      <div className="flex">
        <div className="w-1/2">
          <form onSubmit={handleSubmit}>

            <div className={`bg-green-100 rounded p-5 mb-5 ${isSent ? 'visible' : 'hidden'}`}>
              Your message has been received. Thank you.
              <button onClick={resetForm} className="float-right text-green-700">Close</button>
            </div>

            <div className={`bg-gray-100 rounded p-5 mb-5 ${errMsg !== '' ? 'visible' : 'hidden'}`}>
              Error: {errMsg}
              <button onClick={resetForm} className="float-right text-gray-700">Close</button>
            </div>

            <label htmlFor="email" className="block mb-2">Name</label>
            <input value={name} onChange={e => setName(e.target.value)} type="text" name="name" id="name" className="w-full p-3 border shadow rounded mb-6" />

            <label htmlFor="email" className="block mb-2">Your Email Address</label>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              className="w-full p-3 border shadow rounded mb-6"
              required
            />


            <label htmlFor="message" className="block mb-2">Message</label>
            <input
              value={message}
              onChange={e => setMessage(e.target.value)}
              type="textarea"
              name="message"
              id="message"
              className="w-full p-3 border shadow rounded mb-6"
              style={{ minHeight: 200 }}
            />

            <button type="submit" disabled={loading} className="shadow mt-5 bg-blue-500 text-white px-8 py-3 rounded">
              Send Message
            </button>

          </form>
        </div>

      </div>
    </div>
  );
}
