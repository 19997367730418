/**
 * BpFlatpage - Grabs page data to display
 *
 * 1. Queries backend based on incoming `url` prop
 * 2.
 *
 * Environment variables used:
 *   - REACT_APP_WEBSITE_GUID
 *
 * GraphQL queries used:
 *   - PAGE_DETAIL
 */

import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { BpHelmet } from './BpHelmet';
import { RenderBlock } from '../utils';

const PAGE_DETAIL = gql`
  query PageDetail($url: String!, $websiteGuid: String!) {
    pageDetail(url: $url, websiteGuid: $websiteGuid) {
      title
      getAbsoluteUrl
      created
      modified
      metadata
    }
  }
`;

export const BpFlatpage = ({ url }) => {
  const { loading, error, data } = useQuery(PAGE_DETAIL, {
    variables: {
      websiteGuid: process.env.REACT_APP_WEBSITE_GUID,
      url
    },
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const { pageDetail } = data;

  const pageData = JSON.parse(pageDetail.metadata);

  return (
    <>
      <BpHelmet {...pageData.head} />
      {pageData.blocks.map(b => (
        RenderBlock(b)
      ))}
    </>
  );
};
