/**
 * Bullet Pages SwitchRouter
 *
 * 1. Queries backend for routeList data
 * 2.
 *
 * Environment variables used:
 *   - REACT_APP_WEBSITE_GUID
 *
 * GraphQL queries used:
 *   - ROUTE_LIST
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BpFlatpage } from './BpFlatpage';
// import { BpPostDetail } from './BpPostDetail';
// import { BpCollection } from './BpCollection';
import { NoMatch } from './NoMatch';



export const SwitchRouter = ({ routeList }) => {
  return (
    <Switch>
      {routeList.map(({ id, url, exact, getRouteType }) => (
        <Route key={id} path={url} exact={exact}>
          {getRouteType === 'page' && <BpFlatpage url={url} />}
          {/* {getRouteType === 'post' && <BpPostDetail url={url} exact={exact} />} */}
          {/* {getRouteType === 'collection' && <BpCollection url={url} />} */}
        </Route>
      ))}
      <Route path="*" component={NoMatch} />
    </Switch>
  );
};
