import React from 'react';
import { Helmet } from 'react-helmet';

export const BpHelmet = ({ title, ogTitle, cononicalUrl, ogUrl, ogImage, description }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta property="og:title" content={ogTitle ? ogTitle : title} />
    <link rel="canonical" href={cononicalUrl} />
    <meta property="og:url" content={ogUrl ? ogUrl : cononicalUrl} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:description" content={description} />
  </Helmet>
);
