import React from 'react';
import { FaTwitterSquare, FaFacebookSquare, FaInstagramSquare, FaSoundcloud, FaSpotify, FaYoutubeSquare, FaLinkedin } from 'react-icons/fa';

export const Footer = ({ footerData, socialData }) => {
  const socialList = [
    { id: 1, icon: <FaTwitterSquare />, url: socialData.twitterUrl },
    { id: 2, icon: <FaInstagramSquare />, url: socialData.instagramUrl },
    { id: 3, icon: <FaFacebookSquare />, url: socialData.facebookUrl },
    { id: 4, icon: <FaSoundcloud />, url: socialData.soundcloudUrl },
    { id: 5, icon: <FaSpotify />, url: socialData.spotifyUrl },
    { id: 6, icon: <FaYoutubeSquare />, url: socialData.youtubeUrl },
    { id: 7, icon: <FaLinkedin />, url: socialData.linkedinUrl }
  ];
  return (
    <footer className="bg-gray-900 text-gray-300 mt-8 lg:mt-20 py-10 px-3">
      <div className="container flex">
        {/* <Col>
            <h4>Keep in Touch</h4>
            <p style={{ color: '#909090' }}>We’re always experimenting with new media technology and putting incredible stories in the spotlight.</p>
            <Label for="exampleEmail" style={{ color: '#cccccc' }}>Email Address</Label>
            <Input type="email" name="email" id="exampleEmail" placeholder="me@example.com" style={{ background: 'transparent', borderColor: '#333333', marginBottom: 15 }} />
            <Button color="primary">Subscribe</Button>
          </Col>
          <Col>
            <h4></h4>
          </Col> */}
        <div className="w-full lg:w-1/3">
          <h4 className="text-3xl lg:text-2xl">Contact Us</h4>

          <ul className="flex mb-5 mt-3 text-3xl">
            {socialList.map(({ id, url, icon }) => (
              url &&
              <li key={id} className="mr-5">
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                  {icon}
                </a>
              </li>
            ))}
          </ul>

          <p>{footerData.contactName}
            <br />
            <span className="text-gray-600">
              {footerData.streetAddressLine1}
              <br />
              {footerData.streetAddressCity} {footerData.streetAddressState}, {footerData.streetAddressZip}
            </span>
            <br />
            <span className="text-gray-700">—</span>
            <br />
            <a href={`mailto:${footerData.email}`}>{footerData.email}</a>
            <br /><span className="text-gray-600"><a href={`tel:${footerData.phone}`}>{footerData.phone}</a></span></p>
        </div>
      </div>
    </footer>
  );
}
