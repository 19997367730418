/**
 * Bullet Pages App Base
 *
 * 1. Query backend for websiteDetail data
 * 2. Display Header/Footer conditionally
 * 3. Handoff to <SwitchRouter />
 *
 * Environment variables used:
 *   - REACT_APP_WEBSITE_GUID
 *
 * GraphQL queries used:
 *   - WEBSITE_DETAIL
 */

import React from "react";
import gql from 'graphql-tag';
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';

import { SwitchRouter } from './SwitchRouter';
import { Header, Footer } from '../components';

import '../media/tailwind.generated.css';

const WEBSITE_DETAIL = gql`
  query WebsiteDetail($guid: String!) {
    websiteDetail(guid: $guid) {
      name
      domain
      metadata
      routeSet {
        id
        url
        exact
        getRouteType
      }
    }
  }
`;

export const App = () => {
  const { loading, error, data } = useQuery(WEBSITE_DETAIL, {
    variables: { guid: process.env.REACT_APP_WEBSITE_GUID }
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const website = JSON.parse(data.websiteDetail.metadata);

  return (
    <Router>
      <Header logo={website.logo} mainMenu={website.mainMenu} />
      <SwitchRouter routeList={data.websiteDetail.routeSet} />
      <Footer footerData={website.footerData} socialData={website.socialData} />
    </Router>
  );
}
