import React from 'react';
import {
  AccordionLeftImageRight,
  FullBleedHero,
  FullBleedTwoColWithHeader,
  TwoColCardRight,
  ContactForm
} from '../components';

export const RenderBlock = (block) => {
  return {
    'FullBleedHero': <FullBleedHero key={block.id} {...block} />,
    'TwoColCardRight': <TwoColCardRight key={block.id} {...block} />,
    'FullBleedTwoColWithHeader': <FullBleedTwoColWithHeader key={block.id} {...block} />,
    'AccordionLeftImageRight': <AccordionLeftImageRight key={block.id} {...block} />,
    'ContactForm': <ContactForm key={block.id} {...block} />,
  }[block.componentName];
}
