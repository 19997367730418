import React, { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';

export const AccordionLeftImageRight = ({ content }) => {
  const [accordion, setAccordion] = useState(content.accordionList);

  const toggle = (selectedId) => {
    const currentItem = accordion.find(({ id }) => id === selectedId);
    const remainingItems = accordion.filter(i => i.id !== selectedId);

    remainingItems.forEach(i => i.isOpen = false);

    if (!currentItem.isOpen) {
      currentItem.isOpen = true
    } else {
      currentItem.isOpen = false
    }

    setAccordion([...remainingItems, currentItem]);
  }
  return (
    <div className="container">
      <h1 className="mt-6 lg:mt-20 mb-3 lg:mb-10 lg:text-center text-4xl px-3 text-gray-700">{content.headerText}</h1>
      <div className="flex flex-wrap">

        <div className="w-full lg:w-1/2 px-3 mt-6">
          {accordion.sort((a, b) => (a.id > b.id) ? 1 : -1).map((i, index) => (
            <span key={i.id}>
              <h4 onClick={() => toggle(i.id)} className={`text-2xl cursor-pointer ${index === 0 ? 'mt-0' : 'mt-6'}`}>
                <FaAngleRight className={`transform transition ease-in-out duration-200 text-blue-700 ${i.isOpen && 'rotate-90'} inline-block`} /> {i.title}
              </h4>
              <div className={`block transition-all overflow-hidden ease-in-out duration-200 ${i.isOpen ? 'h-auto' : 'h-0'}`}>
                <div className="mr-10 mt-2 text-sm text-gray-700">
                  <p className="ml-10" style={{ fontSize: 13, marginLeft: 30 }}>{i.description}</p>
                </div>
              </div>
            </span>
          ))}
        </div>

        <div className="order-first lg:order-none w-full lg:w-1/2 px-3">
          <img src={content.image} alt="" className="lg:mt-10 w-full rounded shadow-lg" />
        </div>

      </div>
    </div>
  );
}
