import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { useScrollHandler } from '../hooks';

export const Header = ({ logo, mainMenu }) => {
  const scroll = useScrollHandler();
  return (
    <div className={`w-full fixed z-50 transition duration-500 ease-in-out h-16 ${scroll ? 'bg-gray-900' : 'bg-transparent'}`}>
      <div className="container">

        <Link to="/">
          <img
            src={logo.image}
            alt={logo.alt}
            className={`inline-block transition-all duration-200 ease-in-out ${scroll ? 'h-10 mt-3 sm:mt-0 sm:h-16' : 'h-10 mt-3 sm:mt-0 sm:h-24'}`}
          />
        </Link>

        <div className="float-right mt-5 mr-3">
          <ul className="flex space-x-5">
            {mainMenu.map(i => (i.isActive && (
              <li key={i.id}>
                <NavLink to={i.url} className={`text-gray-400 ${i.class && i.class}`}>
                  {i.title}
                </NavLink>
              </li>
            )
            ))}
          </ul>
        </div>

      </div>
    </div>
  );
}
