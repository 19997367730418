import React from 'react';

export const FullBleedHero = ({ content }) => {
  const { splashImage: image, splashSize: size, head, subhead } = content;

  const getSize = size => ({
    'tall': { mb: -160, h: '70vh' },
    'short': { mb: 0, h: '40vh' }
  }[size])

  return (
    <div
      className="w-full bg-cover bg-no-repeat bg-center flex align-bottom flex-col"
      style={{
        backgroundImage: `url(${image})`,
        height: getSize(size).h,
        maxHeight: 720
      }}
    >
      <div className="flex-1" />
      <div className="container m-auto px-3">
        <div className="w-full lg:w-1/2">
          <h1 className="text-gray-200 mb-1 text-4xl">
            {head}
          </h1>
          <p className="text-gray-500 lg:pr-10 text-2xl mb-12 leading-tight">
            {subhead}
          </p>
        </div>
      </div>
    </div>
  );
}
