import React from 'react';
import { useHistory } from "react-router-dom";

export const TwoColCardRight = ({ content }) => {
  const history = useHistory();
  const handleCta = () => history.push(content.cardCtaUrl);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  }

  return (
    <div className="flex flex-wrap px-3 container md:px-0">
      <div className="w-full lg:w-1/2 md:pr-1">
        <div dangerouslySetInnerHTML={createMarkup(content.col1Html)} />
      </div>
      <div className="w-full lg:w-1/2 mt-10 lg:-mt-32">
        <div className="bg-white shadow-lg rounded-lg border-solid border-gray-300 border">
          <div dangerouslySetInnerHTML={createMarkup(content.col2Html)} />
          <button onClick={handleCta} className="block bg-blue-600 mt-16 mb-10 py-3 rounded w-11/12 text-white text-xl m-auto box-border text-center">
            {content.cardCtaLabel}
          </button>
        </div>
      </div>
    </div>
  );
}
