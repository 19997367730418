import React from 'react';
import { BpHelmet } from './BpHelmet';

export const NoMatch = () => (
  <div className="container">
    <BpHelmet title={`Error 404: Page Not Found - ${process.env.REACT_APP_CLIENT_NAME}`} />
    <h2>404 Not Found</h2>
    <p>The page you were looking for does not exist.</p>
  </div>
);
