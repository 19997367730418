import React from 'react';

export const FullBleedTwoColWithHeader = ({ content }) => {
  function createMarkup(htmlContent) {
    return { __html: htmlContent };
  }
  return (
    <div
      className="w-full mt-10 md:mt-20 py-1 md:py-12 lg:py-24 bg-cover bg-center bg-no-repeat text-gray-200"
      style={{ backgroundImage: `url(${content.fullBleedBackground})` }}
    >
      <div className="container m-auto">
        <div className="flex flex-wrap mt-12 md:mt-0">

          <div className="w-full px-3 mb-3">
            <div className="w-full lg:w-1/2">
              <div dangerouslySetInnerHTML={createMarkup(content.headerHtml)} />
            </div>
          </div>

          <div className="w-full lg:w-1/2 px-3 self-center">
            <img src={content.image} alt="" className="w-full mt-6 mb-10 lg:my-0" />
          </div>

          <div className="w-full lg:w-1/2 px-3 mb-6">
            <div dangerouslySetInnerHTML={createMarkup(content.col2Html)} />
          </div>

        </div>

      </div>
    </div >
  );
}
