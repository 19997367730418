/**
 * Bullpages Entrypoint
 *
 * 1. Initialize Sentry Logging
 * 2. Initialize ApolloClient
 * 3. Wrap App base in ApolloProvider
 * 4. hydrate/render approach is called for by `react-snap`
 *
 * Environment variables used:
 *   - REACT_APP_SENTRY_DSN
 *   - REACT_APP_BACKEND_HOST
 */

import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import { App } from './base';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const rootElement = document.getElementById('root');

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND_HOST}/graphql`,
  // Bullet Pages is browser based and uses cookies for login and session
  // management. We send the cookie along with every request by passing the
  // `credentials: 'include'` option. 'include' and not 'same-origin' because
  // Bullet Page instances are hosted on different domains than our backend.
  // See: https://www.apollographql.com/docs/react/networking/authentication/
  credentials: 'include',
});

const ApolloWrappedApp = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)

if (rootElement.hasChildNodes()) {
  hydrate(<ApolloWrappedApp />, rootElement);
} else {
  render(<ApolloWrappedApp />, rootElement);
}
